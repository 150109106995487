:root {
  --fg-pickup-level1: #fff27b;
  --fg-pickup-level2: #b1a645;
  --fg-pickup-level3: #827505;
  --bg-pickup-level3: #ffeb3b;
  --bg-pickup-level3-shadow: #b3a20f;

  --fg-gray-level1: #ddd;
  --fg-gray-level2: #888;
  --fg-gray-level3: #000;
  --bg-gray-level1: #f3f3f3;
  --bg-gray-level2: #eee;
  --bg-gray-level3: #d6d6d6;
  --bg-gray-level4: #777;
  --bg-gray-level5: #777;

  --fg-main-level1: #88e3ff;
  --fg-main-level2: #46b1d2;
  --fg-main-level3: #00749a;
  --bg-main-level1: #e2f7ff;
  --bg-main-level2: #c7edfb;
  --bg-main-level3: #7bdeff;
  --bg-main-level4: #5cd0f6;
  --bg-main-level5: #0098f1;

  --width-app-maximum: 640px;

  --depth-basic: 1;
  --depth-contents: 2;
  --depth-modal: 10;
  --depth-help: 20;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
button {
  font-size: 1rem;
}
