html {
  height: 100%;
}
body {
  height: 100%;
  color: #333;
  font-family: Helvetica;
}
#root {
  height: 100%;
}
.apps {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform-style: preserve-3d;
}
.pages {
  height: auto;
  min-height: 100%;
}
input, button {
  font-family: Helvetica;
}
img {
  vertical-align: bottom;
}
.pageContents {
  height: 100%;
  position: relative;
  z-index: var(--depth-contents);
  flex: 1 1 auto;
  overflow: scroll;
  background: var(--bg-gray-level5);
}
.header {
  height: 3.5rem;
  flex: 0 0 3.5rem;
  position: relative;
  background: #333;
  color: #eee;
  text-align: center;
}
.headerInner {
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  max-width: var(--width-app-maximum);
  margin: 0 auto;
  padding: 0;
}
.headerHeading {
  flex: 1 1 100%;
  margin: 0;
}
.headerHeadingTitle {
  display: block;
  margin: 0 0 0.25rem 0;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.0;
}
.headerHeadingDescription {
  display: block;
  margin: 0;
  color: var(--fg-main-level1);
  font-size: 0.75rem;
  line-height: 1.0;
  font-weight: bold;
}
.message {
  width: 80%;
  min-width: 200px;
  background: rgba(255, 255, 255, 0.8);
  margin: 3rem auto;
  padding: 0.5rem 1rem;
}
.message p {
  margin: 0;
  font-size: 0.875rem;
  line-height: 2.0;
}
.message p + p {
  margin-top: 1rem;
}
